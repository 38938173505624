/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

.main {
    padding-top: 160px;
    .block {
        position: relative;
        margin-bottom: 70px;
        .calendar {
            width: 230px;
            position: absolute;
            top: 100px;
            right: 0;
        }
        @media screen and (max-width: 1140px) {
            .calendar {
                top: 55px;
            }
        }
        @media screen and (max-width: 991px) {
            margin-bottom: 25px;
            .calendar {
                top: 30px;
            }
        }
        @media screen and (max-width: 767px) {
            .calendar {
                top: 15px;
            }
        }
        @media screen and (max-width: 580px) {
            .calendar {
                width: 100%;
                position: relative;
                top: 0;
                margin: 15px 0;
            }
        }
    }
    @media screen and (max-width: 1140px) {
        padding-top: 0px;
    }
}

/* main slider */
.slider {
	margin: 0 -15px 0px -15px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
			margin: -50px auto 0 auto;
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
            width: auto;
            height: auto;
			margin: 0px 5px;
			button {
				width: 18px;
				height: 18px;
				background-color: transparent;
				border-radius: 100%;
				border: 1px solid #fff;
                padding: 0;
                transition: 300ms;
				&:before {
					display: none;
				}
                &:after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    background-color: #fff;
                    border-radius: 100%;
                    box-sizing: border-box;
                    margin: 0 auto;
                    border: 1px solid #fff;
                    transition: 300ms;
                }
				&:hover {
				    &:after {
                        background-color: #b49157;
                    }
                }
			}
			&.slick-active {
				button {
					background-color: transparent;
                    &:after {
                        background-color: #b49157;
                    }
				}
			}
		}
	}
    @media screen and (max-width: 991px) {
        .slick-dots {
            bottom: 20px;
        }
    }
    @media screen and (max-width: 767px) {
        .slick-slide {
            img {
                width: auto;
                height: 380px;
            }
        }
        .slick-dots {
            bottom: 10px;
        }
    }
    @media screen and (max-width: 580px) {
        .slick-slide {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
/* main slider */

/* main about */
.about {
    padding: 0 15px;
    h1 {
        display: table;
        font-family: $andantino;
        font-size: 60px;
        font-weight: normal;
        text-transform: none;
        &:after {
            height: 18px;
            bottom: -10px;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 15px 0;
    }
    @media screen and (max-width: 767px) {
        h1 {
            font-size: 45px;
            margin: 0 auto;
        }
    }
}
.about__img {
    max-width: 600px;
    background-color: #fff;
    float: right;
    padding: 20px;
    box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
    position: relative;
    margin: -100px 0 45px 45px;
    .img {
        width: 375px;
        background-color: #fff;
        position: absolute;
        left: 0;
        bottom: 15px;
        padding: 20px;
        box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
        transform: rotate(15deg);
    }
    img {
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 1140px) {
        max-width: 500px;
        .img {
            width: 300px;
        }
    }
    
    @media screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        padding: 15px;
        margin: 15px 0 25px;
        .img {
            width: 60%;
            padding: 15px;
        }
    }
    @media screen and (max-width: 480px) {
        padding: 10px;
        .img {
            padding: 10px;
        }
    }
}
/* main about */

/* main cat */
.cat {
    padding: 50px 0;
    h2 {
        text-align: center;
    }
    .row {
        margin: 0 -10px;
        .col-sm-4 {
            padding: 0 5px;
        }
    }
    .cat__col {
        display: block;
        background-color: rgba(255,255,255,0.5);
        padding: 30px;
        box-shadow: 0px 0px 25px rgba(0,0,0,0.1), 0px 10px 30px rgba(0,0,0,0.1);
        .cat__col__img {
            display: block;
            height: 215px;
            margin: 0 0 15px 0;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition: 300ms;
            }
        }
        .cat__col__title {
            display: block;
            font-size: 32px;
            text-align: center;
            transition: 300ms;
        }
        .price {
            display: block;
            font-size: 26px;
            font-weight: bold;
            color: #b49157;
            text-align: center;
            margin: 5px 0 10px;
            span {
                font-weight: normal;
                color: #414141;
            }
        }
        p {
            min-height: 90px;
        }
        a {
            display: block;
            color: #414141;
            &:hover {
                text-decoration: none;
                .cat__col__title {
                    color: #b49157;
                }
                .cat__col__img img {
                    transform: scale(1.1);
                }
            }
        }
        .btn {
            display: table;
            margin: 35px auto;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 25px 0;
        .cat__col {
            padding: 20px;
            .cat__col__title {
                font-size: 26px;
            }
            .price {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 15px 0;
        .cat__col {
            margin: 15px 0;
            p {
                min-height: 0px;
            }
            .btn {
                margin: 20px auto 0;
            }
        }
    }
}
/* main cat */

/* main info */
.info {
    padding-bottom: 65px;
    .info__col {
        .info__col__title {
            display: block;
            min-height: 60px;
            background-color: #f2ebdf;
            font-weight: bold;
            padding: 15px 10px 5px 10px;
            line-height: 1.2;
            margin-bottom: 15px;
        }
    }
    .row {
        margin: 0 -10px;
        .col-sm-4 {
            padding: 0 5px;
        }
    }
    @media screen and (max-width: 991px) {
        padding-bottom: 40px;
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 20px;
        .info__col {
            margin: 15px 0;
            .info__col__title {
                min-height: 0px;
                padding: 10px 10px 10px 10px;
            }
        }
    }
}
/* main info */

/* main content */
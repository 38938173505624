/* footer */
footer {
    background-color: rgba(180, 145, 87, 0.2);
    font-size: 16px;
    padding-top: 55px;
    > .container {
        padding-bottom: 50px;
    }
    ul {
        @include ul-default;
        li {
            margin: 0 0 10px 0;
            a {
                display: block;
                &:hover {
                    text-decoration: none;
                }
            }
            &.active a {
                color: #b49157;
            }
        }
    }
    h6 {
        margin: 0 0 30px;
    }
    .footer--about {
        padding-right: 150px;
        .footer__logo {
            display: table;
            font-family: $andantino;
            font-size: 42px;
            color: #af8a52;
            margin: 0 0 50px 0;
        }
        .socials {
            display: table;
            padding-top: 40px;
            border-top: 1px solid #c6ab82;
            line-height: 1;
            margin-top: 35px;
            ul li {
                @include inline-block;
                margin: 0 30px 0 0;
                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    font-size: 20px;
                    color: #b49157;
                    text-align: center;
                    border: 1px solid #b49157;
                    box-sizing: border-box;
                    padding: 8px 0 0 0;
                    i.fa-facebook {
                        margin-top: 2px;
                    }
                    &:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .footer--contacts {
        ul li {
            margin-bottom: 25px;
            a {
                margin-bottom: 3px;
            }
        }
    }
    .container-fluid {
        .container {
            border-top: 1px solid #cab187;
            padding: 35px 0;
        }
        p {
            @include inline-block;
            margin: 0;
        }
        .artmedia {
            @include inline-block;
            position: relative;
            float: right;
            padding-right: 130px;
            &:hover {
                text-decoration: none;
            }
            img {
                position: absolute;
                top: -4px;
                right: 0;
            }
        }
    }
    &.footer {
        background-color: rgba(255, 251, 246, 0.5);
        border-top: 2px solid #af8a52;
        padding: 35px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .container-fluid {
            .container {
                position: relative;
                border: none;
                padding: 0 15px;
                p, .artmedia {
                    color: #af8a52;
                }
                .artmedia {
                    margin-top: 8px;
                }
                .socials {
                    width: 250px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    text-align: center;
                    margin: 0 auto;
                    transform: translateY(-50%);
                    ul li {
                        @include inline-block;
                        margin: 0 18px;
                        a {
                            display: block;
                            width: 40px;
                            height: 40px;
                            font-size: 20px;
                            text-align: center;
                            color: #af8a52;
                            border: 1px solid #af8a52;
                            padding-top: 6px;
                            box-sizing: border-box;
                            &:hover {
                                text-decoration: none;
                                background-color: #fff;
                            }
                            .fa-facebook {
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &.footer {
            position: relative;
            padding: 20px 0;
        }
    }
    @media screen and (max-width: 1199px) {
        .footer--about  {
            padding-right: 35px;
        }
    }
    @media screen and (max-width: 991px) {
        .footer--about {
            padding-right: 15px;
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 25px;
        > .container {
            padding-bottom: 25px;
        }
        h6 {
            margin-bottom: 15px;
        }
        .footer--about {
            .footer__logo {
                margin-bottom: 15px;
            }
            .socials {
                padding-top: 20px;
                margin: 25px 0;
                ul li {
                    margin-right: 20px;
                }
            }
        }
        .footer--contacts ul li {
            margin-bottom: 15px;
        }
        .container-fluid {
            .container {
                padding: 20px 15px;
            }
        }
        &.footer {
            .container-fluid .container {
                p {
                    display: block;
                    text-align: center;
                }
                .socials {
                    position: relative;
                    transform: translateY(0);
                    margin: 15px auto;
                    ul li {
                        margin: 0 10px;
                    }
                }
                .artmedia {
                    float: none;
                    display: table;
                    margin: 0 auto;
                }
            }
        }
    }
    @media screen and (max-width: 680px) {
        .container-fluid {
            .container {
                p {
                    display: block;
                    text-align: center;
                }
                .artmedia {
                    display: table;
                    float: none;
                    margin: 10px auto 0;
                }
            }
        }
    }
}
/* footer */